import React, { createContext, useState, useEffect } from "react";
import { BsEyeglasses, BsTruckFlatbed } from "react-icons/bs";
import { FaGlasses, FaHourglassEnd } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import apiEndPoints from "../api/apiEndPoints";
// import { useParams } from "react-router-dom";

// import { useParams } from "react-router-dom";

export const DataContext = createContext();

const DataProvider = ({ children }) => {
  //const BASE_URL = "http://52.200.213.224:4000/";

  const BASE_URL = "https://apidev.event-plus.in/api/";
  const navigate = useNavigate();
  const [resultLoginState, setResultLoginState] = useState("");
  const [specificEvent, setSpecificEvent] = useState([]);
  const [checkLogin, setCheckLogin] = useState(false);
  const [logout, setLogout] = useState(false);
  const [publish, setPublish] = useState(false);
  const [passModal, setIsPassModal] = useState(false);
  const [sponsorsList, setSponsorsList] = useState([]);
  const [ButtonLoader, setButtonLoader] = useState(false);
  const [globalLoader, setGlobalLoader] = useState(false);
  const [faqList, setFaqList] = useState([]);
  const [speakers, setSpeakers] = useState([]);
  const [sponsorsLevel, setSponsorsLevel] = useState([]);
  const [agendaList, setAgendaList] = useState([]);
  const [user, setUser] = useState([]);
  // const [sponsor, setsponsor] = useState([]);
  const [pollList, setPollList] = useState([]);
  const [id, setId] = useState("");
  const [deleteBtn, setDeleteBtn] = useState(false);
  const [deleteBtnId, setDeleteBtnId] = useState("");
  const [countryList, setCountryList] = useState([]);
  
  const [publishList, setPublishList] = useState([]);
  const [allCount, setAllCount] = useState([]);
  const [announcementList, setAnnouncementList] = useState([]);
  const [exhibitorList, setExhibitorList] = useState([]);
  const [disableSave, setDisableSave] = useState(false);
  const [leaderboardList, setLeaderboardList] = useState([]);
  const [allPostsList, setAllPostsList] = useState([]);
  const [adminInfo, setAdminInfo] = useState([]);
  const [notificationList, setNotificationList] = useState([]);
  const [pollStatus, setPollStatus] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [meetingsList, setMeetingsList] = useState([]);
  const [agendaDatesState, setAgendaDatesState] = useState([]);
  const [getAgendaSessionState, setGetAgendaSessionState] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [pollModalBtn, setPollModalBtn] = useState(false);
  const [postDisabled, setPostDisabled] = useState(null);
  const [getRegData, setGetRegData] = useState([]);
  const [speakerModal, setSpeakerModal] = useState(false);
  const [checkResponse, setCheckResponse] = useState("");
  const [emailTemplate, setEmailTemplate] = useState([]);
  const [checkInList, setCheckInList] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [isContactList,setIsContactList]=useState(false)
  const [isSendMail,setIsSendMail]=useState(false)
  const [contactList,setContactList]=useState([])
  const [createContactList,setCreateContactList]=useState([])
  const [contactListId,setContactListId]=useState(null)
  const [editExhibitor,setEditExhibitor]=useState({})
  let userToken = JSON.parse(localStorage.getItem("token"));
  let eventId = JSON.parse(localStorage.getItem("eventId"));
  const [activityReport,setActivityReport]=useState([])
  const [exhibitorReport,setExhibitorReport]=useState([])
  const [singleExhibitorReport,setSingleExhibitorReport]=useState([])
  
  const [status, setStatus] = useState("list");
  const [sponsorReport,setSponsorReport]=useState([])
  const [singleSponsorReport,setSingleSponsorReport]=useState([])
  const [attendeeReport,setAttendeeReport]=useState([])
  const [option, setOption] = useState("event-overview");
  const [agendaReport,setAgendaReport]=useState([]);
  const [singleAttendeeReport,setSingleAttendeeReport]=useState([])
  const [singleAgendaReport,setSingleAgendaReport]=useState([])
  const [speakerReport,setSpeakerReport]=useState([])
  const [singleSpeakerReport,setSingleSpeakerReport]=useState([])
  const [sponsorTime,setSponsorTime]=useState([])
  const [disablePosts,setDisablePosts]=useState()
  const [isSpinner,setIsSpinner]=useState(false)
  

  function graphActivityReport(activityReport) {
   console.log(activityReport,"activityReport")
    var months = [
      "jan",
      "feb",
      "mar",
      "apr",
      "may",
      "jun",
      "jul",
      "aug",
      "sep",
      "oct",
      "nov",
      "dec",
    ];

    let obj = {};
       
    const newArr = activityReport?.map((item) => {
      return `${new Date(item.createdAt).getDate()} ${
        months[new Date(item.createdAt).getMonth()]
      }`;
    });
    console.log(newArr,"new array")
    for (let key of newArr) {
      if (obj[key]) obj[key] = ++obj[key];
      else obj[key] = 1;
    }
    setGraphData(obj);
    console.log(obj,"obj")
  }

  //----------------------------------------- Login API --------------------------------
  const LoginApi = (state) => {
    setButtonLoader(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: state.email,
      password: state.password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + "user/login", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          if (result.result.onetimepassword === false) {
            localStorage.setItem("userInfo", JSON.stringify(result.result));
            localStorage.setItem("token", JSON.stringify(result.token));
            let splitUrl = result.result.url.split("?");
            console.log(splitUrl,"splitURl")
            localStorage.setItem(
              "eventId",
              JSON.stringify(splitUrl[1].slice(9, -1))
            );
            getAgendaDatesApi();
            toast.success(result.message);
            setTimeout(() => {
              navigate("/dashboard");
            }, 1000);
          }
          else {
            localStorage.setItem("token", JSON.stringify(result.token));
            navigate("/create-password");
            setResultLoginState("");
          }
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        } else {
          setResultLoginState(result.message);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(()=> setButtonLoader(false));
  };

  // ------------------------------Forgot password API---------------------------------

  const forgotPasswordApi = (state) => {
    var myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: state.email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `user/forgotPassword`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setButtonLoader(false);
          console.log(result, "forgot api result");
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // ------------------------------Reset Password API---------------------------------

  const resetPasswordApi = (state, userId) => {
    console.log(userId, "data state userId");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "confirmPassword": state.confirmPassword,
      "password": state.password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `user/reset-password/${userId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setButtonLoader(false);
          console.log(result, "forgot api result");
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // ------------------------------Add speaker API---------------------------------

  const AddSpeakerApi = (state) => {
    setIsSpinner(true)
   
    
    let userToken = JSON.parse(localStorage.getItem("token"));
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);
    var formdata = new FormData();
    formdata.append("speaker_firstname", state.firstname);
    formdata.append("speaker_lastname", state.lastname);
    formdata.append("title", state.title);
    formdata.append("organization", state.organization);
    formdata.append("position", state.position);
    formdata.append("country", state.country);
    formdata.append("Biography", state.biography);
    formdata.append("image", state.image);
    formdata.append("linkedln_profile", state.linkedln_profile);
    formdata.append("twitter_profile", state.twitter_profile);
    formdata.append("facebook_profile", state.facebook_profile);
    formdata.append("instagram_profile", state.instagram_profile);
    formdata.append("email", state.email);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(BASE_URL + `speaker/addSpeaker/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setIsSpinner(false)
          toast.success(result.message);
          setTimeout(() => {
           
            navigate("/speaker_list");
          }, 1000);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setIsSpinner(false)
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        } else {
          setIsSpinner(false)
          toast.warning(result.message);
        }
      })

      .catch((error) => {
        setIsSpinner(false)
       
        setDisableSave(false);
      });
  };

  // ----------------------------------------get speaker----------------------

  const getSpeakersApi = () => {
    setGlobalLoader(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", JSON.parse(localStorage.getItem("token")));
    let eventId = JSON.parse(localStorage.getItem("eventId"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `speaker/getSpeaker/findAll/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setSpeakers(result.Result);
          setGlobalLoader(false);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
          setGlobalLoader(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setGlobalLoader(false);
      });
  };

  // ----------------------------------------Find One speaker Api----------------------

  const findOneSpeakerApi = (id) => {
    setGlobalLoader(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", JSON.parse(localStorage.getItem("token")));
    let eventId = JSON.parse(localStorage.getItem("eventId"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `speaker/getSpeaker/findOne/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
         
          setGlobalLoader(false);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
          setGlobalLoader(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setGlobalLoader(false);
      });
  };

  //---------------------------------------update speaker api-------------------------------
  const updateSpeakerApi = (state, id) => {
    setId(id);
   setIsSpinner(true)
    var myHeaders = new Headers();
    let userToken = JSON.parse(localStorage.getItem("token"));
    myHeaders.append("token", userToken);

    var formdata = new FormData();
    formdata.append("image", state.image);
    formdata.append("speaker_firstname", state.speaker_firstname);
    formdata.append("email", state.email);
    formdata.append("speaker_lastname", state.speaker_lastname);
    formdata.append("country", state.country);
    formdata.append("Biography", state.Biography);
    formdata.append("position", state.position);
    formdata.append("title", state.title);
    formdata.append("organization", state.organization);
    formdata.append("instagram_profile", state.instagram_profile);
    formdata.append("facebook_profile", state.facebook_profile);
    formdata.append("twitter_profile", state.twitter_profile);
    formdata.append("linkedln_profile", state.linkedln_profile);

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(BASE_URL + `speaker/updateSpeaker/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setIsSpinner(false)
          toast.success(result.message);
          setTimeout(() => {
           
            navigate("/speaker_list");
          }, 1000);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setIsSpinner(false)
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        } else {
          setIsSpinner(false)
          toast.warning(result.message);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };

  //----------------------------delete speaker api---------------------------
  const deleteSpeakerApi = (
    id,
    currentPage,
    limit,
    secondResultCount,
    setSecondResultCount
  ) => {
    var myHeaders = new Headers();
    let userToken = JSON.parse(localStorage.getItem("token"));
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `speaker/deleteSpeaker/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          if (currentPage === pageCount) {
            setSecondResultCount(--secondResultCount);
          }
          toast.success(result.message);
          speakerPaginationApi(currentPage, limit);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");

          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //----------------------------Search speaker api------------
  const searchSpeakerApi = (key, page) => {
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    let userToken = JSON.parse(localStorage.getItem("token"));
    if (key == "") {
      var limit = 15;
    } else {
      var page = 0;
      var limit = 0;
    }
    var myHeaders = new Headers();

    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      BASE_URL +
        `speaker/getSpeaker/findAll/${eventId}?key=${key}&page=${page}&limit=${limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setSpeakers(result.Result);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //----------------------------------Add attendees Api----------------------
  const addAttendeesApi = (state, formData) => {
    setDisableSave(true);
    let userToken = JSON.parse(localStorage.getItem("token"));
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);

    var formdata = new FormData();

    const timestamp = new Date().getTime();
    const randomSeed = timestamp % 1000000;
    const x = Math.sin(randomSeed) * 10000;
    const y = x - Math.floor(x);
    // const name = state.organization.split(" ").join("+")
    if (state.image === "") {
      formdata.append("image", `https://ui-avatars.com/api/?name=${y}`);
    } else {
      formdata.append("image", state.image);
    }

    for (let key in formData) {
      if (formData.hasOwnProperty(key)) {
        const value = formData[key];
        console.log(`Key: ${key}, Value: ${value}`);
        formdata.append(`${key}`, value);
      }
    }

    // formdata.append("Name", state.Name);
    // formdata.append("email", state.email);
    // formdata.append("phone", state.phone);
    formdata.append("organization", state.organization);
    formdata.append("designation", state.designation);
    formdata.append("biography", state.biography);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(BASE_URL + `audience/addaudience/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setTimeout(() => {
            setDisableSave(false);
            navigate("/attendees");
          }, 1000);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        } else {
          toast.warning(result.message);
          setDisableSave(false);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };

  //----------------------------------import User Api----------------------

  const importUserApi = (state) => {
    setDisableSave(true);
    let userToken = JSON.parse(localStorage.getItem("token"));
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);

    var formdata = new FormData();
    formdata.append("file", state.file);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(BASE_URL + `audience/addaudience/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setTimeout(() => {
            setDisableSave(false);
            navigate("/attendees");
          }, 2000);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };

  //------------------------get user Api------------------------
  const getUserApi = () => {
    setGlobalLoader(true);
    var myHeaders = new Headers();
    myHeaders.append("token", JSON.parse(localStorage.getItem("token")));
    let eventId = JSON.parse(localStorage.getItem("eventId"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `audience/getaudience/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setUser(result.Result);
          setGlobalLoader(false);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
          setGlobalLoader(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setGlobalLoader(false);
      });
  };

  //----------------------------------update user Api---------------------------------------
  const updateUserApi = (state, audienceId, api,formData) => {
    console.log(api);
    setDisableSave(true);
    setId(id);
    var myHeaders = new Headers();
    let userToken = JSON.parse(localStorage.getItem("token"));
    myHeaders.append("token", userToken);

    var formdata = new FormData();
    formdata.append("image", state.image);
    for (let key in formData) {
      if (formData.hasOwnProperty(key)) {
        const value = formData[key];
        console.log(`Key: ${key}, Value: ${value}`);
        formdata.append(`${key}`, value);
      }
    }
   
    formdata.append("organization", state.organization);
    formdata.append("designation", state.designation);
    formdata.append("biography", state.biography);

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(BASE_URL + `audience/updateaudience/${audienceId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setTimeout(() => {
            setDisableSave(false);
            if (api === "userapi") navigate("/attendees");
            else navigate("/event_information");
          }, 1000);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        } else {
          toast.warning(result.message);
          setDisableSave(false);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };
  //-----------------------------delete user api--------------------------------
  const deleteUserApi = (
    id,
    currentPage,
    limit,
    secondResultCount,
    setSecondResultCount
  ) => {
    var myHeaders = new Headers();
    let userToken = JSON.parse(localStorage.getItem("token"));
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `audience/deleteaudience/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          if (currentPage === pageCount) {
            setSecondResultCount(--secondResultCount);
          }

          toast.success(result.message);
          attendeesPaginationApi(currentPage, limit);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //----------------------------------search user api---------------------
  const searchUserApi = (key, page) => {
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    let userToken = JSON.parse(localStorage.getItem("token"));
    if (key == "") {
      // var page = 1;
      var limit = 15;
    } else {
      var page = 0;
      var limit = 0;
    }
    var myHeaders = new Headers();

    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      BASE_URL +
        `audience/getaudience/findAll/${eventId}?key=${key}&page=${page}&limit=${limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setUser(result.Result);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //------------------------------add sponsor level--------------------------
  const AddSponsorLevelApi = (state) => {
    setIsSpinner(true)
    let userToken = JSON.parse(localStorage.getItem("token"));
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", userToken);

    var raw = JSON.stringify({
      id: state.id,
      level_name: state.levelname,
      level_color: state.levelColor,
      order: state.order,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `sponsorlevel/addSponsorlevel/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setIsSpinner(false);
          setTimeout(() => {
            
            navigate("/sponsorlevel");
          }, 1000);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        } else {
          toast.warning(result.message);
         setIsSpinner(false)
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };

  //-----------------------get sponsorlevel Api--------------------------------
  const getSponsorLevelApi = () => {
    setGlobalLoader(true);

    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", JSON.parse(localStorage.getItem("token")));
    let eventId = JSON.parse(localStorage.getItem("eventId"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `sponsorlevel/getSponsorlevel/findAll/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          if (result.Result.length === 0) setCheckResponse(true);
          else {
            setCheckResponse(false);
          }
          setSponsorsLevel([...result.Result]);
          setGlobalLoader(false);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
          setGlobalLoader(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setGlobalLoader(false);
      });
  };
  //-------------------------update sponsorlevel Api----------------------------
  const updateSponsorLevelApi = (state, id) => {
    setIsSpinner(true);
    setId(id);
    let userToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      level_name: state.level_name,
      level_color: state.level_color,
      order: state.order,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `sponsorlevel/updateSponsorlevel/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setIsSpinner(false);
          toast.success(result.message);
          setTimeout(() => {
           
            navigate("/sponsorlevel");
          }, 1000);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
         
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        } else {
          setIsSpinner(false);
          toast.warning(result.message);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };
  //---------------------delete sponsorlevel api-----------------------
  const deleteSponsorlevelApi = (id) => {
    setGlobalLoader(true);
    var myHeaders = new Headers();
    let userToken = JSON.parse(localStorage.getItem("token"));
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `sponsorlevel/deleteSponsorlevel/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setGlobalLoader(false);
        if (result.statusCode === 200) {
          toast.success(result.message);
          getSponsorLevelApi();
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        } else {
          toast.warning(result.message);
        }
      })

      .catch((error) => console.log("error", error));
  };

  //----------------------search Sponsor level api-----------------------
  const searchSponsorLevelApi = (key) => {
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    let userToken = JSON.parse(localStorage.getItem("token"));

    var myHeaders = new Headers();

    myHeaders.append("token", userToken);
    let page=0;
    let limit=0;

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(BASE_URL + `sponsorlevel/getSponsorlevel/findAll/${eventId}?key=${key}&page=${page}&limit=${limit}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setSponsorsLevel(result.Result);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };
  // -----------------------------------Update event Api --------------------------------------

  const updateEventApi = (state,setFunction) => {
    console.log(state, "state");
    setDisableSave(true);
    let userToken = JSON.parse(localStorage.getItem("token"));
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);

    var formdata = new FormData();
    
    formdata.append("image", state?.image);

    formdata.append("interest", JSON.stringify(state.interest));
    formdata.append("bannerimage", state.bannerimage);
    
    formdata.append("room", JSON.stringify(state.room));
    formdata.append("gtag", state.gtag);
    formdata.append("logo", state.logo);

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    console.log("state", state.interest);
    fetch(BASE_URL + `event/updateEvent/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setDisableSave(false);
          if(setFunction)
          {
            setFunction(false)
            getEventByIdApi()
          }
         
          toast.success(result.message);
         
        
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");

          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };
  // ---------------------------Get Event using Id ------------------------------------

  const getEventByIdApi = () => {
    let EventId = JSON.parse(localStorage.getItem("eventId"));
    let userToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `event/getEventById/${EventId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setSpecificEvent(result.Result);
          console.log(specificEvent, "in auth");
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // Create Password
  const CreatePasswordApi = (state) => {
    var myHeaders = new Headers();
    myHeaders.append("token", JSON.parse(localStorage.getItem("token")));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      password: state.password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + "user/resetpassword", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //-------------------------add sponsor API----------------------
  const addSponsorApi = (state) => {
   setIsSpinner(true);
  
    let userToken = JSON.parse(localStorage.getItem("token"));
    let eventId = JSON.parse(localStorage.getItem("eventId"));

    var myHeaders = new Headers();
    myHeaders.append("token", userToken);

    var formdata = new FormData();
    formdata.append("sponsor_name", state.sponsor_name);
    formdata.append("level",state.level._id);
    formdata.append("bannerimage", state.bannerimage);
    formdata.append("url", state.url);
    formdata.append("image", state.image);
    formdata.append("description", state.description);
    formdata.append("email", state.email);
    formdata.append("phone", state.phone);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(BASE_URL + `sponsor/addSponsor/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setIsSpinner(false);
          setTimeout(() => {
           
            navigate("/sponsors");
          }, 1000);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        } else {
          toast.warning(result.message);
          setIsSpinner(false);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };
  // ------------------------------get sponsors list-------------------
  const getSponsorList = () => {
    setGlobalLoader(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    myHeaders.append("token", JSON.parse(localStorage.getItem("token")));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `sponsor/getSponsor/findAll/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setSponsorsList(result.Result2);
          setGlobalLoader(false);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })

      .catch((error) => console.log("error", error));
  };
  //------------------------------update sponsor api--------------------------
  const updateSponsorApi = (state, id) => {
    setIsSpinner(true);
    setId(id);
    console.log(id);
    var myHeaders = new Headers();
    let userToken = JSON.parse(localStorage.getItem("token"));
    myHeaders.append("token", userToken);

    var formdata = new FormData();
    formdata.append("bannerimage", state.bannerimage);
    formdata.append("sponsor_name", state.sponsor_name);
    formdata.append("level", JSON.stringify(state.level));
    formdata.append("url", state.url);
    formdata.append("image", state.image);
    formdata.append("description", state.description);
    formdata.append("email", state.email);
    formdata.append("phone", state.phone);

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(BASE_URL + `sponsor/updateSponsor/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setIsSpinner(false);
          setTimeout(() => {
            
            navigate("/sponsors");
          }, 1000);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        } else {
          toast.warning(result.message);
         setIsSpinner(false);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };

  //----------------------delete Sponsor Api-------------------------
  const deleteSponsorApi = (
    id,
    currentPage,
    limit,
    secondResultCount,
    setSecondResultCount
  ) => {
    var myHeaders = new Headers();
    let userToken = JSON.parse(localStorage.getItem("token"));
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `sponsor/deleteSponsor/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          if (currentPage === pageCount) {
            setSecondResultCount(--secondResultCount);
          }
          toast.success(result.message);
          sponsorPaginationApi(currentPage, limit);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };
  //-------------------search sponsor api------------------------------
  const searchSponsorApi = (key,page) => {
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    let userToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();

    if (key == "") {
      // var page = 1;
      var limit = 15;
    } else {
      var page = 0;
      var limit = 0;
    }

    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      BASE_URL +
        `sponsor/getSponsor/findAll/${eventId}?key=${key}&page=${page}&limit=${limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setSponsorsList(result.Result2);
          console.log(result.Result2, "fghjk");
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // ------------------------------Create FAQ API-------------------
  const AddFaqApi = (state) => {
   setIsSpinner(true);
    let userToken = JSON.parse(localStorage.getItem("token"));
    let eventId = JSON.parse(localStorage.getItem("eventId"));

    var myHeaders = new Headers();
    myHeaders.append("token", userToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      FAQText: state.FAQText,
      FAQAnswer: state.FAQanswer,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `faq/addFAQ/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setIsSpinner(false);
          toast.success(result.message);
          setTimeout(() => {
            
            navigate("/faq");
          }, 2000);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          setIsSpinner(false);
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
        else {
          setIsSpinner(false)
          toast.warning(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setIsSpinner(false);
      });
  };
  //--------------------add publish api--------------------
  const addPublishApi = () => {
    setDisableSave(true);
    var myHeaders = new Headers();
    let userToken = JSON.parse(localStorage.getItem("token"));
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `publish/isPublish/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setDisableSave(false);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };
  //----------------get Publish api----------------------------------
  const getPublishApi = () => {
    setGlobalLoader(true);
    let userToken = JSON.parse(localStorage.getItem("token"));
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(BASE_URL + `publish/getPublish/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setPublishList(result.result);
          setGlobalLoader(false);
          if(result.result.length==0)
          {
            setCheckResponse(true)
          }
          else{
            setCheckResponse(false)
          }
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //-----------------speaker Pagination Api-----------
  const speakerPaginationApi = (page, limit) => {
    setGlobalLoader(true);

    let eventId = JSON.parse(localStorage.getItem("eventId"));
    let userToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      BASE_URL + `speaker/getSpeaker/findAll/${eventId}?page=${page}&limit=${limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          if (result.Result.length === 0) setCheckResponse(true);
          else {
            setCheckResponse(false);
          }
          setSpeakers(result.Result);
          setGlobalLoader(false);
          setPageCount(result.pageCount);
          setTotalCount(result.totalCount);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
          setGlobalLoader(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setGlobalLoader(false);
      });
  };
  //--------------attendees pagination api----------
  const attendeesPaginationApi = (page, limit) => {
    setGlobalLoader(true);
    var myHeaders = new Headers();
    myHeaders.append("token", JSON.parse(localStorage.getItem("token")));
    let eventId = JSON.parse(localStorage.getItem("eventId"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      BASE_URL + `audience/getaudience/findAll/${eventId}?page=${page}&limit=${limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setGlobalLoader(false);
        if (result.statusCode === 200) {
          if (result.Result.length === 0) setCheckResponse(true);
          else {
            setCheckResponse(false);
          }
          setUser(result.Result);
          setGlobalLoader(false);

          setPageCount(result.pageCount);
          setTotalCount(result.totalCount);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };
//--------------Find One Attendee Api----------
const findOneAttendeeApi = (id) => {
  setGlobalLoader(true);
  var myHeaders = new Headers();
  myHeaders.append("token", JSON.parse(localStorage.getItem("token")));
  let eventId = JSON.parse(localStorage.getItem("eventId"));

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  fetch(
    BASE_URL + `audience/getaudience/findOne/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      setGlobalLoader(false);
      if (result.statusCode === 200) {
        
        setGlobalLoader(false);

        
      } else if (
        result.message === "TOKEN_EXPIRED" ||
        result.message === "INVALID_TOKEN"
      ) {
        toast.error("Token Expired,Login Again!");
        setCheckLogin(false);
        navigate("/");
        localStorage.removeItem("userInfo");
        localStorage.removeItem("token");
        localStorage.removeItem("eventId");
      }
    })
    .catch((error) => console.log("error", error));
};

  // const ExportXLX = () => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("token", JSON.parse(localStorage.getItem("token")));
  //   let eventId = JSON.parse(localStorage.getItem("eventId"));

  //   var requestOptions = {
  //     method: "GET",
  //     headers: myHeaders,
  //     redirect: "follow",
  //   };

  //   fetch(
  //     BASE_URL + `/audience/getaudience/${eventId}?page=${0}&limit=${0}`,
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {
  //       if (result.statusCode === 200) {
  //         getXLXS(result.Result);
  //           setUser(result.Result);
  //         setGlobalLoader(false);
  //         console.log(result,"result")
  //         setPageCount(result.pageCount)
  //         setTotalCount(result.totalCount)
  //       } else if (
  //         result.message === "TOKEN_EXPIRED" ||
  //         result.message === "INVALID_TOKEN"
  //       ) {
  //         toast.error("Token Expired,Login Again!");
  //         setCheckLogin(false);
  //         navigate("/");
  //         localStorage.removeItem("userInfo");
  //         localStorage.removeItem("token");
  //         localStorage.removeItem("eventId");
  //       }
  //     })
  //     .catch((error) => console.log("error", error));
  // };

  // const getXLXS = (arrayData) => {
  //   const wb = utils.book_new();
  //   const ws = utils.json_to_sheet(arrayData);
  //   utils.book_append_sheet(wb, ws, "Data");
  //   writeFileXLSX(wb, "Attendees Data.xlsx");
  // };

  //------------sponsor  Pagination  Api------------
  const sponsorPaginationApi = (page, limit) => {
    setGlobalLoader(true);
    var myHeaders = new Headers();
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    myHeaders.append("token", JSON.parse(localStorage.getItem("token")));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      BASE_URL + `sponsor/getSponsor/findAll/${eventId}?page=${page}&limit=${limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setGlobalLoader(false);
        if (result.statusCode === 200) {
          if (result.Result2.length === 0) setCheckResponse(true);
          else {
            setCheckResponse(false);
          }
          setSponsorsList(result.Result2);
          setGlobalLoader(false);
          setPageCount(result.pageCount);
          setTotalCount(result.totalCount);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })

      .catch((error) => console.log("error", error));
  };

  //------------Find One sponsor  Api------------
  const findOneSponsorApi = (id) => {
    setGlobalLoader(true);
    var myHeaders = new Headers();
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    myHeaders.append("token", JSON.parse(localStorage.getItem("token")));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      BASE_URL + `sponsor/getSponsor/findOne/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
       
        if (result.statusCode === 200) {
          
          setGlobalLoader(false);
        
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })

      .catch((error) => console.log("error", error));
  };

  // ------------------------------Publish Pagination API-------------------

  const publishPaginationApi = (page, limit) => {
    setGlobalLoader(true)
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    let userToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();

    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      BASE_URL + `publish/getPublish/${eventId}?page=${page}&limit=${limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setGlobalLoader(false);
          setPublishList(result.Result);
         if(result.Result.length==0)
         {
          setCheckResponse(true)
         }
         else
         {
          setCheckResponse(false)
         }
          setPageCount(result.pageCount);
          setTotalCount(result.totalCount);
          console.log(result.Result, "count");
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) =>{ 
        setGlobalLoader(false)
        console.log("error", error)});
  };

  // ------------------------------get FAQ API-------------------
  const getFaqApi = () => {
    setGlobalLoader(true);
    let userToken = JSON.parse(localStorage.getItem("token"));
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `FAQ/getFAQ/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setFaqList(result.Result);

          setGlobalLoader(false);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // ------------------------------Faq Pagination API-------------------

  const faqPaginationApi = (page, limit) => {
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    let userToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();

    myHeaders.append("token", userToken);
    setGlobalLoader(true);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      BASE_URL + `FAQ/getFAQ/findAll/${eventId}?page=${page}&limit=${limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          if (result.Result.length === 0) setCheckResponse(true);
          else {
            setCheckResponse(false);
          }

          setFaqList(result.Result);

          setPageCount(result.pageCount);
          setTotalCount(result.totalCount);
          console.log(result.result, "count");
          setGlobalLoader(false);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
          setGlobalLoader(false);
        }
      })

      .catch((error) => {
        setGlobalLoader(false);
        console.log("error", error);
      });
  };

  // ------------------------------Update FAQ API-------------------
  const updateFaqApi = (state, id) => {
    setId(id);
   setIsSpinner(true);
    let userToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      FAQText: state.FAQText,
      FAQAnswer: state.FAQAnswer,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `FAQ/updateFAQ/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setIsSpinner(false)
          toast.success(result.message);
          setTimeout(() => {
          
            navigate("/faq");
          }, 2000);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          setIsSpinner(false)
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
        else {
          setIsSpinner(false)
          toast.warning(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };

  // ------------------------------Delete FAQ API-------------------
  const deleteFaqApi = (
    id,
    currentPage,
    limit,
    secondResultCount,
    setSecondResultCount
  ) => {
    let userToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `FAQ/deleteFAQ/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          if (currentPage === pageCount) {
            setSecondResultCount(--secondResultCount);
          }
          toast.success(result.message);
          faqPaginationApi(currentPage, limit);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // ------------------------------Create Agenda API-------------------

  const createAgendaApi = (state) => {
    setIsSpinner(true);
    console.log(state, "add agenda");
    console.log(state, "agenda state");
    let userToken = JSON.parse(localStorage.getItem("token"));
    let eventId = JSON.parse(localStorage.getItem("eventId"));

    var myHeaders = new Headers();
    myHeaders.append("token", userToken);

    var formdata = new FormData();

    formdata.append("agenda_name", state.agenda_name);
    formdata.append("startTime", state.startTime);
    formdata.append("description", state.description);
    formdata.append("image", state.image);
    formdata.append("startDate", state.agenda_Date);
    formdata.append("speaker", JSON.stringify(state.speaker));
    formdata.append("sponsor", JSON.stringify(state.sponsor));
    formdata.append("endTime", state.endTime);
    formdata.append("Location", state.Location);
    formdata.append("interest", JSON.stringify(state.interest));
    formdata.append("document", state.document);
    if(state.status=="")
    formdata.append("status", "Disable");
  else
  formdata.append("status", state.status);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(BASE_URL + `agenda/addAgenda/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setIsSpinner(false);
          setTimeout(() => {
           
            navigate("/agenda-list");
          }, 2000);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          setIsSpinner(false);
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setIsSpinner(false);
      });
  };

  // ------------------------------Get Agenda by ID-------------------

  const getAgendaById = () => {
    setGlobalLoader(true);
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    let userToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `agenda/getagenda/findAll/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setGlobalLoader(false);
          setAgendaList(result.Result);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) =>{ 
        setGlobalLoader(false);
        console.log("error", error)});
  };

  // ------------------------------Agenda Pagination Api-------------------

  const agendaPaginationApi = (page, limit) => {
    setGlobalLoader(true);

    let eventId = JSON.parse(localStorage.getItem("eventId"));
    let userToken = JSON.parse(localStorage.getItem("token"));

    var myHeaders = new Headers();

    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      BASE_URL + `agenda/getagenda/findAll/${eventId}?page=${page}&limit=${limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setGlobalLoader(false);
        if (result.statusCode === 200) {
          if (result.Result.length === 0) setCheckResponse(true);
          else {
            setCheckResponse(false);
          }
          setGlobalLoader(false);
          setAgendaList(result.Result);

          setPageCount(result.pageCount);
          setTotalCount(result.totalCount);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // ------------------------------Find One Agenda Api-------------------

  const findOneAgendaApi = (id) => {
    setGlobalLoader(true);

    let eventId = JSON.parse(localStorage.getItem("eventId"));
    let userToken = JSON.parse(localStorage.getItem("token"));

    var myHeaders = new Headers();

    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      BASE_URL + `agenda/getagenda/findOne/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        
        if (result.statusCode === 200) {
          
          setGlobalLoader(false);
          
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // ------------------------------Update Agenda API-------------------
  const updateAgendaApi = (state, id,checkNotification) => {
    setId(id);
    setIsSpinner(true);
    console.log(typeof(checkNotification))
    let userToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);

    var formdata = new FormData();

    formdata.append("agenda_name", state.agenda_name);
    formdata.append("startTime", state.startTime);
    formdata.append("description", state.description);
    formdata.append("image", state.image);
    formdata.append("startDate", state.startDate);
    formdata.append("speaker", JSON.stringify(state.speaker));
    formdata.append("sponsor", JSON.stringify(state.sponsor));
    formdata.append("endTime", state.endTime);
    formdata.append("Location", state.Location);
    formdata.append("interest", JSON.stringify(state.interest));
    formdata.append("status", state.status=="" ? "Disable":"Active");
    formdata.append("sendNotification", checkNotification);
    

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(BASE_URL + `agenda/updateagenda/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setIsSpinner(false)
          toast.success(result.message);
          setTimeout(() => {
           
            navigate("/agenda-list");
          }, 2000);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          setIsSpinner(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
        setIsSpinner(false);
      });
  };

  //------------------------------Delete Agenda API-------------------
  const deleteAgendaApi = (
    id,
    currentPage,
    limit,
    secondResultCount,
    setSecondResultCount
  ) => {
    let userToken = JSON.parse(localStorage.getItem("token"));

    var myHeaders = new Headers();
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `agenda/deleteagenda/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          if (currentPage === pageCount) {
            setSecondResultCount(--secondResultCount);
          }
          toast.success(result.message);
          agendaPaginationApi(currentPage, limit);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  // ------------------------------Search Agenda API-------------------

  const searchAgendaApi = (key) => {
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    let userToken = JSON.parse(localStorage.getItem("token"));

    if (key == "") {
      // var page = 1;
      var limit = 15;
    } else {
      var page = 0;
      var limit = 0;
    }
    var myHeaders = new Headers();

    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      BASE_URL +
        `agenda/getagenda/findAll/${eventId}?key=${key}&page=${page}&limit=${limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setAgendaList(result.Result);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //-------------------------eventprivacy policy update api-------------
  const eventPrivacyPolicyApi = (state) => {
    setDisableSave(true);
    let userToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);
    var formdata = new FormData();
    formdata.append("privacyPolicy", state.privacyPolicy);

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    console.log("privacypolicy", eventId);
    // console.log("state.privacyPolicy",state.privacyPolicy)
    fetch(BASE_URL + `event/updateEvent/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setTimeout(() => {
            setDisableSave(false);
            navigate("/event_information");
          }, 1000);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };
  //------------event termsandcondition-------------
  const eventTermsAndConditionApi = (state) => {
    setDisableSave(true);
    let userToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);
    var formdata = new FormData();
    formdata.append("termsAndConditions", state.termsAndConditions);

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    // console.log("termsAndConditions",eventId)
    console.log("state.termsAndConditions", state.termsAndConditions);
    fetch(BASE_URL + `event/updateEvent/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setTimeout(() => {
            setDisableSave(false);
            navigate("/event_information");
          }, 1000);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };

  //-----------------------------Add Poll API-------------------------------
  const addPollApi = (state, texts) => {
   setIsSpinner(true);
    let userToken = JSON.parse(localStorage.getItem("token"));
    let eventId = JSON.parse(localStorage.getItem("eventId"));

    var myHeaders = new Headers();
    myHeaders.append("token", userToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      pollText: state.pollText,
      startTime: state.startTime,
      endTime: state.endTime,
      agenda_id: state.agenda_id,

      options: [...texts],
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `poll/addPoll/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setIsSpinner(false)
          toast.success(result.message);
          setTimeout(() => {
            
            navigate("/polls");
          }, 2000);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          setIsSpinner(false)
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
        else {
          setIsSpinner(false)
          toast.warning(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setIsSpinner(false)
      });
  };

  //-----------------------------Get Poll API-------------------------------

  const getPollApi = () => {
    setGlobalLoader(true);
    let userToken = JSON.parse(localStorage.getItem("token"));
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `poll/getPoll/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setGlobalLoader(false);
          setPollList(result.Result);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // -----------------------------Poll Pagination API-------------------
  const pollPaginationApi = (page, limit) => {
    setGlobalLoader(true);
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    let userToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();

    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      BASE_URL + `poll/getPoll/findAll/${eventId}?page=${page}&limit=${limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setGlobalLoader(false);
        if (result.statusCode === 200) {
          if (result.Result.length === 0) setCheckResponse(true);
          else {
            setCheckResponse(false);
          }

          setPollList(result.Result);

          setPageCount(result.pageCount);
          setTotalCount(result.totalCount);
          console.log(result.result, "count");
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // Delete Poll API
  const deletePollApi = (
    id,
    currentPage,
    limit,
    secondResultCount,
    setSecondResultCount
  ) => {
    let token = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();
    myHeaders.append("token", token);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `poll/deletePoll/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          if (currentPage === pageCount) {
            setSecondResultCount(--secondResultCount);
          }
          toast.success(result.message);
          pollPaginationApi(currentPage, limit);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        } else {
          toast.warning(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  //Update poll
  const updatePollApi = (editPoll, pollId, texts) => {
    setIsSpinner(true)
    setDisableSave(true);
    let userToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      agenda_id: editPoll.agenda_id,
      pollText: editPoll.pollText,
      options: [...texts],
      startTime: new Date(editPoll.startTime),
      endTime: new Date(editPoll.endTime),
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `poll/updatePoll/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setIsSpinner(false)
          toast.success(result.message);
          setTimeout(() => {
           
            navigate("/polls");
          }, 2000);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          setIsSpinner(false)
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
        else {
          setIsSpinner(false)
          toast.warning(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setIsSpinner(false)
      });
  };

  //-----------------------------Enable Poll API-------------------------------

  const enablePollApi = (id,currentPage,
    limit,
    secondResultCount,
    setSecondResultCount) => {
    let userToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();

    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `poll/enablePoll/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          if (currentPage === pageCount) {
            setSecondResultCount(--secondResultCount);
          }
          setPollStatus(id);
          pollPaginationApi(currentPage, limit);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        } else {
          toast.warning(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  //-----------------------------Enable Poll API-------------------------------
  const disablePollApi = (id,currentPage,limit,
    secondResultCount,
    setSecondResultCount) => {
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
     pollStatus: false,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `poll/updatePoll/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          if (currentPage === pageCount) {
            setSecondResultCount(--secondResultCount);
          }
           toast.success(result.message);
          
          pollPaginationApi(currentPage, limit);
         
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        } else {
          toast.warning(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  // Admin Change Password API

  const changePasswordApi = (state) => {
    var myHeaders = new Headers();
    myHeaders.append("token", JSON.parse(localStorage.getItem("token")));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      password: state.oldPassword,
      newpassword: state.newPassword,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + "user/changepassword", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setIsPassModal(false);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
        if (result.statusCode === 401) {
          toast.warning(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  //---------------------------------------Get Country API-------------------------------
  const getCountryApi = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(BASE_URL + `country/getall`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setCountryList([...result.Result]);
      })
      .catch((error) => console.log("error", error));
  };

  // Speaker, Session, Attendee Count Api
  const AllCountApi = () => {
    setGlobalLoader(true)
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `event/countEvents/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setGlobalLoader(false)
        if (result.statusCode === 200) {
          setAllCount(result.result);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        } else {
          toast.warning(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  // ------------------------------Add Announcement API-------------------

  const addAnnouncementApi = (state) => {
    setIsSpinner(true)
    let userToken = JSON.parse(localStorage.getItem("token"));
    let eventId = JSON.parse(localStorage.getItem("eventId"));

    var myHeaders = new Headers();
    myHeaders.append("token", userToken);
    myHeaders.append("Content-Type", "application/json");

    var stateObj = {
      title: state.title,
      description: state.description,
      Date: state.Date,
      Time: state.Time,
    };
    if (state.Date === "" && state.Time === "") {
      delete stateObj.Date;
      delete stateObj.Time;
    }
    var raw = JSON.stringify(stateObj);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `announce/add/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setIsSpinner(false)
          toast.success(result.message);
          setTimeout(() => {
           
            navigate("/announcement");
          }, 2000);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          setIsSpinner(false)
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
        else {
          setIsSpinner(false)
          toast.warning(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setIsSpinner(false)
      });
  };

  // ------------------------------Get Announcement API-------------------

  const getAnnouncementApi = () => {
    setGlobalLoader(true);
    let userToken = JSON.parse(localStorage.getItem("token"));
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `announce/get/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setGlobalLoader(false);
          setAnnouncementList(result.Result);

          if (result.Result.length === 0) {
            setCheckResponse(true);
          } else {
            setCheckResponse(false);
          }
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => {
        setGlobalLoader(false)
        console.log("error", error)});
  };

  // ------------------------------Announcement Pagination API-------------------
  const announcementPaginationApi = (page, limit) => {
    setGlobalLoader(true);

    let eventId = JSON.parse(localStorage.getItem("eventId"));
    let userToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();

    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      BASE_URL + `announce/get/findAll/${eventId}?page=${page}&limit=${limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setGlobalLoader(false);
        if (result.statusCode === 200) {
          if (result.Result.length === 0) setCheckResponse(true);
          else {
            setCheckResponse(false);
          }

          setAnnouncementList(result.Result);

          setPageCount(result.pageCount);
          setTotalCount(result.totalCount);
          console.log(result.result, "count");
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };
  // ------------------------------Update Announcement API-------------------

  const updateAnnouncementApi = (state, announcementId) => {
    setId(announcementId);
    setIsSpinner(true)

    let userToken = JSON.parse(localStorage.getItem("token"));

    var myHeaders = new Headers();
    myHeaders.append("token", userToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      title: state.title,
      description: state.description,
      Date: state.Date,
      Time: state.Time,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `announce/edit/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setIsSpinner(false)
          toast.success(result.message);
          setTimeout(() => {
           
            navigate("/announcement");
          }, 2000);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          setIsSpinner(false)
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
        else {
          setIsSpinner(false)
          toast.warning(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setIsSpinner(false)
      });
  };

  // ------------------------------delete Announcement API-------------------

  const deleteAnnouncementApi = (
    id,
    currentPage,
    limit,
    secondResultCount,
    setSecondResultCount
  ) => {
    let userToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `announce/delete/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          if (currentPage === pageCount) {
            setSecondResultCount(--secondResultCount);
          }
          toast.success(result.message);
          announcementPaginationApi(currentPage, limit);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // ------------------------------Add Exhibitor API-------------------

  const addExhibitorApi = (state) => {
    setIsSpinner(true)
console.log("hello")
    console.log(state, "state");
    let userToken = JSON.parse(localStorage.getItem("token"));
    let eventId = JSON.parse(localStorage.getItem("eventId"));

    var myHeaders = new Headers();
    myHeaders.append("token", userToken);

    var formdata = new FormData();
    Array.from(state.galleryImage).forEach((image) => {
      formdata.append("galleryImage", image);
    });
    formdata.append("name", state.name);
    formdata.append("contactNumber", state.contactNumber);
    formdata.append("description", state.description);
    formdata.append("email", state.email);
    formdata.append("websiteUrl", state.websiteUrl);
    formdata.append("logo", state.logo);

    //formdata.append("galleryImage",state.galleryImage);

    formdata.append("bannerImage", state.bannerImage);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    console.log(formdata, "formdata");

    fetch(BASE_URL + `exhibitor/addExhibitor/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
        setIsSpinner(false);
          toast.success(result.message);
          setTimeout(() => {
           
            navigate("/exhibitors");
          }, 2000);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          setIsSpinner(false);
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
        else {
          setIsSpinner(false)
          toast.warning(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setIsSpinner(false);
      });
  };

  // ------------------------------Get Exhibitor API-------------------

  const getExhibitorApi = () => {
    setGlobalLoader(true);
    let userToken = JSON.parse(localStorage.getItem("token"));
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `exhibitor/getExhibitor/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setGlobalLoader(false);
          setExhibitorList([...result.result]);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // ------------------------------Exhibitor Pagination API-------------------
  const exhibitorPaginationApi = (page, limit) => {
    console.log(page,limit,id,"exhibitor")
    setGlobalLoader(true);
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    let userToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();

    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      BASE_URL +
        `exhibitor/getExhibitor/findAll/${eventId}?page=${page}&limit=${limit}
       
       `,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setGlobalLoader(false);
        if (result.statusCode === 200) {
          if (result.result.length === 0) setCheckResponse(true);
          else {
            setCheckResponse(false);
          }

          setExhibitorList(result.result);

          setPageCount(result.pageCount);
          setTotalCount(result.totalCount);
          console.log(result.result, "count");
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //------------------------------Find One Exhibitor API-------------------
  const findOneExhibitorApi = (id) => { 
    
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    let userToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();

    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      BASE_URL +
        `exhibitor/getExhibitor/findOne/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setGlobalLoader(false);
        if (result.statusCode === 200) {
          

          setEditExhibitor(result.result);

          
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // ------------------------------Search Exhibitor API-------------------

  const searchExhibitorApi = (key, page) => {
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    let userToken = JSON.parse(localStorage.getItem("token"));
    if (key == "") {
      // var page = 1;
      var limit = 15;
    } else {
      var page = 0;
      var limit = 0;
    }
    var myHeaders = new Headers();

    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      BASE_URL +
        `exhibitor/getExhibitor/findAll/${eventId}?key=${key}&page=${page}&limit=${limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setExhibitorList(result.result);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // ------------------------------Update Exhibitor API-------------------

  const updateExhibitorApi = (state, exhibitorId, galleryImageExists) => {
    console.log(state, "update state");
    console.log(galleryImageExists, "gallery iamge Exist");
    setId(exhibitorId);
  setIsSpinner(true)
    let userToken = JSON.parse(localStorage.getItem("token"));

    var myHeaders = new Headers();
    myHeaders.append("token", userToken);

    var formdata = new FormData();

    formdata.append("name", state.name);
    formdata.append("contactNumber", state.contactNumber);
    formdata.append("description", state.description);
    formdata.append("email", state.email);
    formdata.append("websiteUrl", state.websiteUrl);
    formdata.append("logo", state.logo);

    formdata.append("galleryImageExists", JSON.stringify(galleryImageExists));

    const newArr = state.galleryImage.filter((item) => {
      return typeof item === "object";
    });
    newArr.forEach((image) => {
      formdata.append("galleryImage", image);
    });

    formdata.append("bannerImage", state.bannerImage);

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(BASE_URL + `exhibitor/updateExhibitor/${exhibitorId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setIsSpinner(false)
          toast.success(result.message);
          setTimeout(() => {
          
            navigate("/exhibitors");
          }, 2000);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          setIsSpinner(false)
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
        else {
          setIsSpinner(false)
          toast.warning(result.message);
        }
      })
      .catch((error) => {
        setIsSpinner(false)
        setDisableSave(false);
      });
  };

  // ------------------------------Delete Exhibitor API-------------------
  const deleteExhibitorApi = (
    id,
    currentPage,
    limit,
    secondResultCount,
    setSecondResultCount
  ) => {
    console.log(id);
    let userToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `exhibitor/deleteExhibitor/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          if (currentPage === pageCount) {
            setSecondResultCount(--secondResultCount);
          }
          toast.success(result.message);
          exhibitorPaginationApi(currentPage, limit);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // ------------------------------Get Leaderboard API-------------------

  const getLeaderboardApi = () => {
    setGlobalLoader(true);
    let userToken = JSON.parse(localStorage.getItem("token"));
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `leaderboard/get/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setGlobalLoader(false);
          console.log("entered");
          setLeaderboardList([...result.Result]);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // ------------------------------Leaderboard Pagination API-------------------
  const leaderboardPaginationApi = (page, limit) => {
    setGlobalLoader(true);
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    let userToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();

    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      BASE_URL + `leaderboard/get/findAll/${eventId}?page=${page}&limit=${limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          if (result.Result.length === 0) setCheckResponse(true);
          else {
            setCheckResponse(false);
          }

          setLeaderboardList(result.Result);
          setGlobalLoader(false);
          setPageCount(result.pageCount);
          setTotalCount(result.count);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
          setGlobalLoader(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setGlobalLoader(false);
      });
  };

  // ------------------------------Get All Posts  API-------------------
  const getAllPostsApi = () => {
    setGlobalLoader(true);
    let userToken = JSON.parse(localStorage.getItem("token"));
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `Post/getAllPost/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          if (result.result.length === 0) setCheckResponse(true);
          else {
            setCheckResponse(false);
          }
          setGlobalLoader(false);

          setAllPostsList([...result.result]);
          console.log(result.result);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setGlobalLoader(false);
      });
  };

  // ------------------------------Get admin Info Api-------------------

  const getAudienceById = () => {
    var myHeaders = new Headers();
    myHeaders.append("token", JSON.parse(localStorage.getItem("token")));
    let adminId = JSON.parse(localStorage.getItem("userInfo"))._id;

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `audience/getaudiencebyId/${adminId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          // setGlobalLoader(false);
          console.log(result.Result, "Resuult");
          setAdminInfo(result.Result);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //---------------update admin info api----------
  const updateAudienceApi = (state) => {
    console.log(state, "state");
    let userToken = JSON.parse(localStorage.getItem("token"));

    var myHeaders = new Headers();
    myHeaders.append("token", userToken);
    let adminId = JSON.parse(localStorage.getItem("userInfo"))._id;

    var formdata = new FormData();
    formdata.append("image", state.profileImage);

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    console.log(state, "stateabc");
    console.log("state", state.interest);
    fetch(BASE_URL + `audience/updateaudiencebyId/${adminId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          getAudienceById();
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // ------------------------------Get Notification Api-------------------

  const getNotificationApi = () => {
    setGlobalLoader(true);
    let userToken = JSON.parse(localStorage.getItem("token"));
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `Post/getreport/findAll/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          if (result.result.length === 0) setCheckResponse(true);
          else {
            setCheckResponse(false);
          }
          setGlobalLoader(false);

          setNotificationList([...result.result]);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setGlobalLoader(false);
      });
  };
  // ------------------------------Get Meeting List Api-------------------

  const getMeetingsListApi = () => {
    setGlobalLoader(true);
    let userToken = JSON.parse(localStorage.getItem("token"));
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("token", userToken);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,

      redirect: "follow",
    };

    fetch(
      BASE_URL + `bookingSlot/getBookingSlot/Admin/${eventId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
      console.log(result.statusCode,"code")
      if(result.statusCode===501)
      {
        setMeetingsList(0)
        setCheckResponse(true)
      }
        if (result.statusCode === 200) {
          if (result.result.length === 0) setCheckResponse(true);
          else {
            setCheckResponse(false);
          }
          setGlobalLoader(false);


          setMeetingsList(result.result);
        
        }
      
        
        else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => {
      console.log("error", error)
      setGlobalLoader(false)
      }
      );
  };

  // Get dates for Agenda
  const getAgendaDatesApi = () => {
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    fetch(BASE_URL + `event/noOfDaysinEvent/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setAgendaDatesState(result.Result);
          setStartDate(result.Result[0]);
        }
      })
      .catch((error) => console.log("error", error));
  };

  // Get Session According to Dates
  const getSessionApi = (date) => {
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    setGlobalLoader(true);
    let userToken = JSON.parse(localStorage.getItem("token"));

    var myHeaders = new Headers();
    myHeaders.append("token", userToken);
    myHeaders.append("Content-Type", "application/json");

    if (date === undefined) {
      var raw = JSON.stringify({
        date: startDate,
      });
    } else {
      raw = JSON.stringify({
        date: date,
      });
    }

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(BASE_URL + `agenda/getagenda/findAll/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setGetAgendaSessionState(result.Result);
          setGlobalLoader(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  //-----------------------------Disable social media post API-------------------------------
  const disablePostApi = (id,setDisable,module) => {
    let handleResponse = (id) => {
      if (postDisabled === id) {
        setPostDisabled(!null);
        return false;
      }
      setPostDisabled(id);
    };
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,

      redirect: "follow",
    };

    fetch(BASE_URL + `Post/deletePost/${module=="enable"?"enable":"disable"}/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setDisable(false)
          handleResponse(id);
        
         
          toast.success(result.message);
          if(module=="enable")
          {
            getDisablePostApi()
          }
          else{
            getAllPostsApi();
          }
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        } else {
          toast.warning(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  // ------------------------------Create Custom Registartion API-------------------

  const createRegistrationApi = (state) => {
    let userToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("token", userToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(state);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BASE_URL}rules/addRules`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          window.location.reload()
        } 
        else if(result.statusCode===409)
        {
          toast.error(result.message);
        }
        
        else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  const getRegistraition = () => {
    let userToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("token", userToken);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${BASE_URL}rules/getRulesByGroupID/${JSON.parse(
        localStorage.getItem("eventId")
      )}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setGetRegData(result.result);
          // toast.success(result.message);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  const getEmailTemplate = () => {
    let userToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AVf9J3sL1wpYYb3yik7alDgpZ-rZWCyDO.4Hnse3fmDZTwIM%2BeaC39DLVZxVG8rxmw07dtGmBBt1I"
    );

    var raw = "";

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    console.log("xcvbn4567fgh");

    fetch(`${BASE_URL}audience/getTemplates/findAll`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setEmailTemplate(result.result);
          // console.log("xcvbn4567fgh")
          // setGetRegData(result.result)
          // toast.success(result.message);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  const updateEmailTemplate = (sendEmailData, emailID) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AmeW-sndzFr_-ffF7uYKK7UP_B9QThDT3.XIiIbu1R6qXGJfbtobxqO31xtbi%2BHe4wB%2BT0AMn3N%2Fo"
    );

    var raw = JSON.stringify({
      newHtmlContent: sendEmailData,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BASE_URL}audience/updateTemplates/${emailID}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          getEmailTemplate()
          // setEmailTemplate(result.result);
          // console.log("xcvbn4567fgh")
          // setGetRegData(result.result)
          // toast.success(result.message);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //----------------------------------------Send meeting mail API----------------

  const postMeetingEmailApi = (slotId) => {
    let userToken = JSON.parse(localStorage.getItem("token"));
    
    var myHeaders = new Headers();
   
    myHeaders.append("token", userToken);

    var raw = "";

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,

      redirect: "follow",
    };

    fetch(BASE_URL + `bookingSlot/adminSendMail/${slotId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setGlobalLoader(false);
        if (result.statusCode === 200) {
          toast.success(result.message);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //----------------------------Search Announcement Api------------
  const searchAnnouncementApi = (key, page) => {
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    let userToken = JSON.parse(localStorage.getItem("token"));
    if (key == "") {
      var limit = 15;
    } else {
      var page = 0;
      var limit = 0;
    }
    var myHeaders = new Headers();

    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      BASE_URL +
        `announce/get/findAll/${eventId}?key=${key}&page=${page}&limit=${limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setAnnouncementList(result.Result);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //----------------------------------------Send meeting Notification API----------------

  const meetingNotificationApi = (slotId) => {
    let userToken = JSON.parse(localStorage.getItem("token"));
    
    var myHeaders = new Headers();
   
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "POST",
      headers:myHeaders,
      redirect: "follow",
    };

    fetch(
      BASE_URL + `bookingSlot/adminSendNotification/${slotId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setGlobalLoader(false);
        if (result.statusCode === 200) {
          toast.success(result.message);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //----------------------------Search checkIn api------------
  const searchCheckInApi = (agendaId, key) => {
    let userToken = JSON.parse(localStorage.getItem("token"));

    var myHeaders = new Headers();

    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      BASE_URL + `agenda/getagendaAttendee/${agendaId}?search=${key}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setCheckInList(result.result);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //----------------------------------------Get Dummy Graph Data API----------------

  const getDummyGraphDataApi = () => {
    setGlobalLoader(true)
    var requestOptions = {
      method: "GET",

      redirect: "follow",
    };

    fetch(BASE_URL + `activity/DummygraphData/`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
       if(result.statusCode==200)
       {
        setGlobalLoader(false)
        setGraphData([...result.insertedData]);
       }
         
        
      })
      .catch((error) => {
        setGlobalLoader(false)
        console.log("error", error)});
  };

  // Create Contact List Api
  const createContactListApi = (state) => {
   console.log(state,"state")
   setIsSpinner(true)
    let userToken = JSON.parse(localStorage.getItem("token"));
    let eventId = JSON.parse(localStorage.getItem("eventId"));


    var myHeaders = new Headers();
    myHeaders.append("token", userToken);
    myHeaders.append("Content-Type", "application/json");

   
      var raw = JSON.stringify({
        name: state.name,
      });
   

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(BASE_URL + `contactUs/CreateContactList/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200){
          setIsSpinner(false)
          setIsContactList(false);
          toast.success(result.message);
         contactListPaginationApi(1,15)
        }
        else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        } else {
          toast.warning(result.message);
          setIsSpinner(false);
        }
       
      })
      .catch((error) => {
        setIsSpinner(false)
        console.log("error", error)});
  };

  //----------------------------Get Contactlist Api------------
  const getContactListApi = () => {
    setGlobalLoader(true)
    let userToken = JSON.parse(localStorage.getItem("token"));
    let eventId = JSON.parse(localStorage.getItem("eventId"));

    var myHeaders = new Headers();

    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      BASE_URL + `contactUs/getContactList/findAll/${eventId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          if (result.result.length === 0) 
          setCheckResponse(true);
          else {
            setCheckResponse(false);
          }
          setGlobalLoader(false)
         setContactList(result.result)
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => {
        setGlobalLoader(false)
        console.log("error", error)});
  };

  // ------------------------------Create Contact Api---------------------------------

  const createContactApi = (state,id,isContactActive,name) => {
    setIsSpinner(true)
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    let userToken = JSON.parse(localStorage.getItem("token"));

    var myHeaders = new Headers();
    myHeaders.append("token", userToken);
   

   
    if(state.file)
    {
      console.log("hello1")
      var formdata = new FormData();
      formdata.append("file", state.file);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      }; 
    }
else
{
  console.log("hello2")
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    "firstName": state.firstName,
    "lastName": state.lastName,
  
    "phoneNumber":state.phoneNumber,
    "email":state.email,
    "position": state.position,
    "organization": state.organization,
    "sendInvitationMail":state.sendInvitationMail,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
}
    

    fetch(BASE_URL + `contactUs/add/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setIsSpinner(false)
          toast.success(result.message)
          setTimeout(() => {
           navigate("/addcontactlist",{state:{id:id,name:name,isContactActive:isContactActive}})
           
          }, 1000);
        }
          else if(result.statusCode===409)
          {
           toast.warn(result.message)
           setIsSpinner(false)
          }
         else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          setIsSpinner(false)
          toast.error("Token Expired,Login Again!");
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
        else {
          toast.warning(result.message);
          setIsSpinner(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  //----------------------------Get Create Contact List Api------------
  const getCreateContactListApi = (id) => {
    console.log(id,"id")
    setGlobalLoader(true)
    let userToken = JSON.parse(localStorage.getItem("token"));
  

    var myHeaders = new Headers();

    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      BASE_URL + `contactUs/get/findAll/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          if (result.result.length === 0) 
          setCheckResponse(true);
          else {
            setCheckResponse(false);
          }
          setGlobalLoader(false)
         setCreateContactList(result.result)
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => {
        setGlobalLoader(false)
        console.log("error", error)});
  };

  //----------------------------delete contactList api---------------------------
  const deleteContactListApi = (id,limit) => {
    console.log(id,limit,"contact")
    var myHeaders = new Headers();
    let userToken = JSON.parse(localStorage.getItem("token"));
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `contactUs/deleteContactList/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
         toast.success(result.message)
         contactListPaginationApi(1,limit)
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
   
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //---------------update ContactList Api----------
  const updateContactListApi = (state,id,setIsEdit,limit) => {
    console.log(id,"id")
    setIsSpinner(true)
    let userToken = JSON.parse(localStorage.getItem("token"));

    var myHeaders = new Headers();
    myHeaders.append("token", userToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: state.name,
    });

    

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
   
    fetch(BASE_URL + `contactUs/updateContactList/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setIsSpinner(false)
          toast.success(result.message);
         setIsEdit(false)
          contactListPaginationApi(1,limit)
         
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          setIsSpinner(false)
          toast.error("Token Expired,Login Again!");
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
       
         else {
          toast.warning(result.message);
          setIsSpinner(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  //------------------------------Update Contact Api---------------------------------

  const updateContactApi = (state,updateId,id,name,isContactActive) => {
   
    setIsSpinner(true);
    let userToken = JSON.parse(localStorage.getItem("token"));

    var myHeaders = new Headers();
    myHeaders.append("token", userToken);
   

    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "firstName": state.firstName,
      "lastName": state.lastName,
    
      "phoneNumber":state.phoneNumber,
      "email":state.email,
      "position": state.position,
      "organization": state.organization,
      "sendInvitationMail":state.sendInvitationMail,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `contactUs/updateContact/${updateId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setIsSpinner(false);
          toast.success(result.message)
          setTimeout(() => {
            navigate("/addcontactlist",{state:{id:id,name:name,isContactActive:isContactActive}})
          
          }, 1000);
        } else if (
         
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          setIsSpinner(false);
          toast.error("Token Expired,Login Again!");
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
        else {
          toast.warning(result.message);
          setIsSpinner(false);
        }
      })
      .catch((error) => {
        setIsSpinner(false);
        console.log("error", error)});
  };

   //----------------------------Delete Contact api---------------------------
   const deleteContactApi = (deleteBtnId) => {
   
  
    var myHeaders = new Headers();
    let userToken = JSON.parse(localStorage.getItem("token"));
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `contactUs/deleteContact/${deleteBtnId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
         toast.success(result.message)
         getCreateContactListApi(contactListId)
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
   
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //----------------ContactList Pagination Api-----------
  const contactListPaginationApi = (page, limit) => {
    setGlobalLoader(true);

    let eventId = JSON.parse(localStorage.getItem("eventId"));
    let userToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      BASE_URL + `contactUs/getContactList/findAll/${eventId}?page=${page}&limit=${limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          if (result.Result.length === 0) 
          setCheckResponse(true);
          else {
            setCheckResponse(false);
          }
          setGlobalLoader(false)
        setContactList(result.Result)
          setPageCount(result.pageCount);
          setTotalCount(result.totalCount);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
          setGlobalLoader(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setGlobalLoader(false);
      });
  };

  //----------------Create ContactList Pagination Api-----------
  const createContactListPaginationApi = (id,page, limit) => {
    setGlobalLoader(true);

    let eventId = JSON.parse(localStorage.getItem("eventId"));
    let userToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      BASE_URL + `contactUs/get/findAll/${id}?page=${page}&limit=${limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          if (result.Result.length === 0) 
          setCheckResponse(true);
          else {
            setCheckResponse(false);
          }
          setGlobalLoader(false)
          console.log("hellocontact")
         setCreateContactList(result.Result)
          setPageCount(result.pageCount);
          setTotalCount(result.totalCount);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
          setGlobalLoader(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setGlobalLoader(false);
      });
  };

  //----------------------------Search ContactList api------------
  const searchContactListApi = (key, page) => {
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    let userToken = JSON.parse(localStorage.getItem("token"));
    if (key == "") {
      var limit = 15;
    } else {
      var page = 0;
      var limit = 0;
    }
    var myHeaders = new Headers();

    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      BASE_URL +
        `contactUs/getContactList/findAll/${eventId}?key=${key}&page=${page}&limit=${limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
         setContactList(result.Result);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //----------------------------Search Create ContactList api------------
  const searchCreateContactListApi = (id,key, page) => {
    let eventId = JSON.parse(localStorage.getItem("eventId"));
    let userToken = JSON.parse(localStorage.getItem("token"));
    if (key == "") {
      var limit = 15;
    } else {
      var page = 0;
      var limit = 0;
    }
    var myHeaders = new Headers();

    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      BASE_URL +
        `contactUs/get/findAll/${id}?key=${key}&page=${page}&limit=${limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
        setCreateContactList(result.Result);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //------------------------------Send Email Api---------------------------------

  const sendEmailApi = (selectedItems,state,setSelectedItems) => {
    setIsSpinner(true);
    let userToken = JSON.parse(localStorage.getItem("token"));

    var myHeaders = new Headers();
    myHeaders.append("token", userToken);
   

    myHeaders.append("Content-Type", "application/json");
    if (Array.isArray(selectedItems)) {
    
      const isArrayOfObjects = selectedItems.every(item => typeof item === 'object');
    
      if (isArrayOfObjects) {
        var raw = JSON.stringify({
          "data":[...selectedItems],
          "templateName":state.template
        });
       
      } else {
        var raw = JSON.stringify({
          "listId":[...selectedItems],
          "templateName":state.template
        });
      }
    } 
    
   

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  
    fetch(BASE_URL + `contactUs/sendEmail/`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setIsSpinner(false);
          toast.success(result.message)
         setSelectedItems([])
          
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          setIsSpinner(false);
          toast.error("Token Expired,Login Again!");
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        } else{
        setIsSpinner(false);
        toast.warning(result.message);
        setIsSpinner(false);
      }
      })
      .catch((error) => console.log("error", error));
  };

  //------------------------------Get Activity Report Api---------------------------------

  const getActivityReportApi = (state) => {
   setGlobalLoader(true)
     console.log(state,"state")
     let userToken = JSON.parse(localStorage.getItem("token"));
 
     var myHeaders = new Headers();
     myHeaders.append("token", userToken);
    
 
     myHeaders.append("Content-Type", "application/json");
     var raw = JSON.stringify({
      "startDate":state.startDate,
      "endDate":state.endDate
    });
     
    
 
     var requestOptions = {
       method: "POST",
       headers: myHeaders,
       body: raw,
       redirect: "follow",
     };
   
     fetch(BASE_URL + `Activity/eventOverView/`, requestOptions)
       .then((response) => response.json())
       .then((result) => {
         if (result.statusCode === 200) {
          if(result.result.registerUsers.docs.length==0)
          {
            setCheckResponse(true)
          }
          else
          {
            setCheckResponse(false)
          }
          setGlobalLoader(false)
           setActivityReport(result.result)
           graphActivityReport(result.result.registerUsers.docs)
           
         } else if (
           result.message === "TOKEN_EXPIRED" ||
           result.message === "INVALID_TOKEN"
         ) {
           toast.error("Token Expired,Login Again!");
           navigate("/");
           localStorage.removeItem("userInfo");
           localStorage.removeItem("token");
           localStorage.removeItem("eventId");
         }
       })
       .catch((error) => {
        setGlobalLoader(false);
        console.log("error", error)}
        );
   };
 
   //------------------------------Get Exhibitor Report Api---------------------------------

  const getExhibitorReportApi = (state,page,size) => {
   setGlobalLoader(true);
    console.log(state,"state")
    let userToken = JSON.parse(localStorage.getItem("token"));

    var myHeaders = new Headers();
    myHeaders.append("token", userToken);
   

    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
     "startDate":state.startDate,
     "endDate":state.endDate
   });
    
   

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  
    fetch(BASE_URL + `Activity/ExhibitorReport?page=${page}&size=${size}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setGlobalLoader(false)
          if(result.Result.ExhibitorResponse.exhibitors.length==0)
          {
            setCheckResponse(true)
          }
          else
          {
            setCheckResponse(false)
          }
         setExhibitorReport(result.Result)
         setPageCount(result.Result.ExhibitorResponse.pagination.pages)
         setTotalCount(result.Result.ExhibitorResponse.pagination.total);
          
          
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) =>{ 
        setGlobalLoader(false)
        console.log("error", error)});
  };

  // ----------------------------------------get Single Exhibitor Report----------------------

  const getSingleExhibitorReportApi = (id,page,size) => {
   setGlobalLoader(true)
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", JSON.parse(localStorage.getItem("token")));
    

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `Activity/getSingleExhibitorReport/${id}?page=${page}&size=${size}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          if(result.Result.profileVisit.length==0)
          {
            setCheckResponse(true)
          }
          else{
            setCheckResponse(false)
          }
          setGlobalLoader(false)
         setSingleExhibitorReport(result.Result.profileVisit);
         setPageCount(result.Result.pages);
         setTotalCount(result.Result.total);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
          setGlobalLoader(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setGlobalLoader(false);
      });
  };

  //------------------------------Get Attendee Report Api---------------------------------

  const getAttendeeReportApi = (state,page,size) => {
   
   setGlobalLoader(true)
    let userToken = JSON.parse(localStorage.getItem("token"));

    var myHeaders = new Headers();
    myHeaders.append("token", userToken);
   

    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
     "startDate":state.startDate,
     "endDate":state.endDate
   });
    
   

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  
    fetch(BASE_URL + `Activity/attendeeReport/?page=${page}&size=${size}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
         setGlobalLoader(false)
         if(result.result.AttendeeFind?.docs.length==0)
         {
          setCheckResponse(true)
         }
         else
         {
          setCheckResponse(false)
         }
          setAttendeeReport(result.result)
          setPageCount(result?.result?.AttendeeFind?.pages);
          setTotalCount(result?.result?.AttendeeFind?.total);
          
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => {
        setGlobalLoader(false)
        console.log("error", error)});
  };

  //------------------------------Get Sponsor Report Api---------------------------------

  const getSponsorReportApi = (state,page,size) => {
   setGlobalLoader(true)
   console.log(page,"page");
   console.log(size,"size");
    let userToken = JSON.parse(localStorage.getItem("token"));

    var myHeaders = new Headers();
    myHeaders.append("token", userToken);
   

    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
     "startDate":state.startDate,
     "endDate":state.endDate
   });
    
   

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  
    fetch(BASE_URL + `Activity/sponserReport?page=${page}&size=${size}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          if (result.Result.sponserLevelResponse?.sponsors.length === 0) {
            setCheckResponse(true);
          } else {
            setCheckResponse(false);
          }
          setGlobalLoader(false)
        setSponsorReport(result.Result)
          // graphActivityReport(result.result.registerUsers)
          setPageCount(result.Result.sponserLevelResponse.pagination.pages);
          setTotalCount(result.Result.sponserLevelResponse.pagination.total);
        
          
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => {
        setGlobalLoader(false)
        console.log("error", error)});
  };

  // ----------------------------------------get Single Exhibitor Report----------------------

  const getSingleSponsorReportApi = (id,page,size) => {
    setGlobalLoader(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", JSON.parse(localStorage.getItem("token")));
    

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `Activity/getSingleSponserReport/${id}?page=${page}&size=${size}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setGlobalLoader(false)
          if(result.result.profileVisit.length==0)
          {
            setCheckResponse(true);
          }
          else
          {
            setCheckResponse(false)
          }
        setSingleSponsorReport(result.result)
        setPageCount(result.result.pages);
        setTotalCount(result.result.total);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
          setGlobalLoader(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setGlobalLoader(false);
      });
  };

  //----------------------------------------get Single Exhibitor Report----------------------

  const getSingleAttendeeReportApi = (id) => {
  setGlobalLoader(true)
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", JSON.parse(localStorage.getItem("token")));
    

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `Activity/viewUserAnalyticProfile/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setGlobalLoader(false)
        if (result.statusCode === 200) {
      
        setSingleAttendeeReport(result.result)
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
          setGlobalLoader(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setGlobalLoader(false);
      });
  };

  //------------------------------Get Agenda Report Api---------------------------------

  const getAgendaReportApi = (state,page,size) => {
   
    setGlobalLoader(true)
     let userToken = JSON.parse(localStorage.getItem("token"));
 
     var myHeaders = new Headers();
     myHeaders.append("token", userToken);
    
 
     myHeaders.append("Content-Type", "application/json");
     var raw = JSON.stringify({
      "startDate":state.startDate,
      "endDate":state.endDate
    });
     
    
 
     var requestOptions = {
       method: "POST",
       headers: myHeaders,
       body: raw,
       redirect: "follow",
     };
   
     fetch(BASE_URL + `Activity/agendaReport?page=${page}&size=${size}`, requestOptions)
       .then((response) => response.json())
       .then((result) => {
         if (result.statusCode === 200) {
        setGlobalLoader(false)
        if(result.result.totalAgendas?.agendaInfo)
        {
          setCheckResponse(true)
        }
        else
        {
          setCheckResponse(false)
        }
          setAgendaReport(result.result)
           setPageCount(result.result.totalAgendas.pages);
           setTotalCount(result.result.totalAgendas.total);
         
           
         } else if (
           result.message === "TOKEN_EXPIRED" ||
           result.message === "INVALID_TOKEN"
         ) {
           toast.error("Token Expired,Login Again!");
           navigate("/");
           localStorage.removeItem("userInfo");
           localStorage.removeItem("token");
           localStorage.removeItem("eventId");
         }
       })
       .catch((error) => {
        setGlobalLoader(false)
        console.log("error", error)});
   };

   //----------------------------------------get Single Agenda Report----------------------

  const getSingleAgendaReportApi = (id,page,size) => {
    setGlobalLoader(true)
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", JSON.parse(localStorage.getItem("token")));
    

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `Activity/getSingleAgendaReport/${id}?page=${page}&size=${size}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
        setGlobalLoader(false)
        setSingleAgendaReport(result.Result)
        setPageCount(result?.Result?.attendeeCheckIn?.totalPages);
        setTotalCount(result?.Result?.attendeeCheckIn?.totalAttendees);
      
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
          setGlobalLoader(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setGlobalLoader(false);
      });
  };

  //------------------------------Get Speaker Report Api---------------------------------

  const getSpeakerReportApi = (state,page,size) => {
   
    setGlobalLoader(true)
    let userToken = JSON.parse(localStorage.getItem("token"));

    var myHeaders = new Headers();
    myHeaders.append("token", userToken);
   

    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
     "startDate":state.startDate,
     "endDate":state.endDate
   });
    
   

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  
    fetch(BASE_URL + `Activity/speakerReport?page=${page}&size=${size}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
       setGlobalLoader(false)
       if(result.result.speakerAgenda?.speakerInAgenda.length==0)
       {
        setCheckResponse(true)
       }
       else{
        setCheckResponse(false)
       }
      
        setSpeakerReport(result.result);
          setPageCount(result.result.speakerAgenda.pages);
          setTotalCount(result.result.speakerAgenda.total);
        
          
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) =>{ 
        setGlobalLoader(false)
        console.log("error", error)});
  };

  //----------------------------------------get Single Speaker Report----------------------

  const getSingleSpeakertApi = (id,page,size) => {
    setGlobalLoader(true)
  
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", JSON.parse(localStorage.getItem("token")));
    

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `Activity/singleSpeaker/${id}?page=${page}&size=${size}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setGlobalLoader(false)
        if (result.statusCode === 200) {
          if(result.Result.agendaInfo?.docs.length==0)
          {
            setCheckResponse(true);
          }
          else
          {
            setCheckResponse(false)
          }
        setSingleSpeakerReport(result.Result)
        setPageCount(result.Result.agendaInfo.pages);
        setTotalCount(result.Result.agendaInfo.total);
      
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
          setGlobalLoader(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setGlobalLoader(false);
      });
  };

  //------------------------------Send Attendee Email Api---------------------------------

  const sendAttendeeEmailApi = (selectedItems,setSelectedItems) => {
    console.log(selectedItems,"selectedItemss")
   setIsSpinner(true)
     let userToken = JSON.parse(localStorage.getItem("token"));
 
     var myHeaders = new Headers();
     myHeaders.append("token", userToken);
    
 
     myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({
      "to": [...selectedItems]
    });
    
 
     var requestOptions = {
       method: "POST",
       headers: myHeaders,
       body: raw,
       redirect: "follow",
     };
   
     fetch(BASE_URL + `audience/sendAttendeeMail`, requestOptions)
       .then((response) => response.json())
       .then((result) => {
         if (result.statusCode === 200) {
          setIsSpinner(false);
           toast.success(result.message)
          setSelectedItems([])
           
         } else if (
           result.message === "TOKEN_EXPIRED" ||
           result.message === "INVALID_TOKEN"
         ) {
          setIsSpinner(false);
           toast.error("Token Expired,Login Again!");
           navigate("/");
           localStorage.removeItem("userInfo");
           localStorage.removeItem("token");
           localStorage.removeItem("eventId");
         }
         else {
          setIsSpinner(false);
          toast.warning(result.message);
          setIsSpinner(false);
        }
       })
       .catch((error) => {
        setIsSpinner(false);
        console.log("error", error)});
   };

   //-------------------Get Sponsor Date And Time------------------------

   const getSponsorDateAndTime = (sponsor_id,clicks,user_id) => {
    let userToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();
   
    myHeaders.append("token", userToken);
  

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${BASE_URL}Activity/clickWithTime/${clicks}/${sponsor_id}/${user_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setSponsorTime(result.Result)
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //-------------------Get Sponsor Date And Time------------------------

  const getExhibitorDateAndTime = (sponsor_id,clicks,user_id) => {
    let userToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();
   
    myHeaders.append("token", userToken);
  

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${BASE_URL}Activity/exhibitorClickWithTime/${clicks}/${sponsor_id}/${user_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setSponsorTime(result.Result)
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //-----------------------------Delete Registration Column Api-------------------------------
  const deleteRegistrationColumnApi = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);
   

   

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
     
      redirect: "follow",
    };

    fetch(BASE_URL + `rules/deleteRules/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
         
           
          toast.success(result.message);
         getRegistraition()
         
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        } else {
          toast.warning(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };


  //----------------------------Get Disable Post Api------------
  const getDisablePostApi = () => {
    setGlobalLoader(true)
    let userToken = JSON.parse(localStorage.getItem("token"));
    let eventId = JSON.parse(localStorage.getItem("eventId"));

    var myHeaders = new Headers();

    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      BASE_URL + `Post/getDisablePosts/${eventId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          if (result.result.length === 0) 
          setCheckResponse(true);
          else {
            setCheckResponse(false);
          }
          setDisablePosts(result.result)
          setGlobalLoader(false)
          
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => {
        setGlobalLoader(false)
        console.log("error", error)});
  };

   //------------------------------Enable/Disable Agenda status API-------------------
   const changeAgendaStatusApi = (
    status,id, currentPage,
    limit,
    secondResultCount,
    setSecondResultCount
  ) => {
    let userToken = JSON.parse(localStorage.getItem("token"));

    var myHeaders = new Headers();
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `agenda/updateStatus/${status==true?"Active":"Disable"}/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          if (currentPage === pageCount) {
            setSecondResultCount(--secondResultCount);
          }
          toast.success(result.message);
          agendaPaginationApi(currentPage, limit);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("eventId");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

 
  

  useEffect(() => {
    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/signup" ||
      window.location.pathname === "/terms-condition" ||
      window.location.pathname === "/privacy-policy"
    ) {
      localStorage.removeItem("userInfo");
      localStorage.removeItem("eventId");
    } else if (window.location.pathname === "/reset-password/"+ window.location.pathname.split("/").pop() || window.location.pathname === "/forgot-password" || window.location.pathname === "/create-password" || window.location.pathname === "/delete-account") {
      setCheckLogin(false);
    } else if (
      window.location.pathname === "/event" ||
      window.location.pathname === "/speaker" ||
      window.location.pathname === "/sponsors" ||
      window.location.pathname === "/notification" ||
      window.location.pathname === "/chat" ||
      window.location.pathname === "/view-events" ||
      window.location.pathname === "/event_information" ||
      window.location.pathname === "/event_agenda" ||
      window.location.pathname === "/agenda_note" ||
      window.location.pathname === "/speaker_list" ||
      window.location.pathname === "/add_speaker" ||
      window.location.pathname === "/import-attendees" ||
      window.location.pathname === "/agenda-list" ||
      window.location.pathname ===
        "/update-agenda/" + window.location.pathname.split("/").pop() ||
      window.location.pathname === "/new_session" ||
      window.location.pathname === "/import_agenda" ||
      window.location.pathname === "/sponsorlevel" ||
      window.location.pathname === "/importsponsor" ||
      window.location.pathname === "/announcement" ||
      window.location.pathname === "/add-announcement" ||
      window.location.pathname ===
        "/update-sponsor/" + window.location.pathname.split("/").pop() ||
      window.location.pathname === "/addsponsor" ||
      window.location.pathname === "/addsponsorlevel" ||
      window.location.pathname === "/attendees" ||
      window.location.pathname === "/add-attendees" ||
      window.location.pathname === "/dashboard" ||
      window.location.pathname === "/faq" ||
      window.location.pathname === "/polls" ||
      window.location.pathname === "/add-polls" ||
      window.location.pathname === "/polls-response" ||
      window.location.pathname === "/add-faq" ||
      window.location.pathname ===
        "/update_speaker/" + window.location.pathname.split("/").pop() ||
      window.location.pathname ===
        "/update-faq/" + window.location.pathname.split("/").pop() ||
      window.location.pathname ===
        "/update_user/" + window.location.pathname.split("/").pop() ||
      window.location.pathname ===
        "/update-sponsorlevel/" + window.location.pathname.split("/").pop() ||
      window.location.pathname ===
        "/update-announcement/" + window.location.pathname.split("/").pop() ||
      window.location.pathname === "/published-log" ||
      window.location.pathname ===
        "/update-polls/" + window.location.pathname.split("/").pop() ||
      window.location.pathname === "/leaderboard" ||
      window.location.pathname === "/socialwall" ||
      window.location.pathname === "/exhibitors" ||
      window.location.pathname === "/reports" ||
      window.location.pathname === "/tutorial" ||
      window.location.pathname === "/add-exhibitor" ||
      window.location.pathname ===
        "/update-announcement/" + window.location.pathname.split("/").pop() ||
      window.location.pathname ===
        "/update-exhibitor/" + window.location.pathname.split("/").pop() ||
      window.location.pathname === "/users" ||
      window.location.pathname === "/user-roles" ||
      window.location.pathname === "/time-line" ||
      window.location.pathname === "/activity" ||
      window.location.pathname === "/event-details" ||
      window.location.pathname === "/event-privacypolicy" ||
      window.location.pathname === "/activity" ||
      window.location.pathname === "/new-role" ||
      window.location.pathname === "/add_user" ||
      window.location.pathname === "/meetings" ||
      window.location.pathname === "/registration" ||
      window.location.pathname === "/registration" ||
      window.location.pathname === "email-template" ||
      "/user/" + window.location.pathname.split("/").pop() ||
      "/speaker/" + window.location.pathname.split("/").pop() ||
      "/agenda/" + window.location.pathname.split("/").pop() ||
      "/sponsor/" + window.location.pathname.split("/").pop() ||
      "/exhibitor/" + window.location.pathname.split("/").pop()
    ) {
      setCheckLogin(true);
    } else {
      setCheckLogin(false);
    }
  }, [window.location.pathname]);

  // All count api load by default
  useEffect(() => {
    if (
      window.location.pathname === "/delete-account" ||
      window.location.pathname === "/" ||
      window.location.pathname === "/forgot-password" ||
      window.location.pathname === "/create-password" ||
      window.location.pathname === "/reset-password" ||
      window.location.pathname ===
        "/reset-password" + window.location.pathname.split("/").pop()
    ) {
    } else {
      AllCountApi();
    }
  }, [speakers, sponsorsList, user, agendaList, faqList, pollList]);

  return (
    <DataContext.Provider
      value={{
        LoginApi,
        resultLoginState,
        setResultLoginState,
        AddSpeakerApi,
        updateEventApi,
        getEventByIdApi,
        specificEvent,
        setSpecificEvent,
        logout,
        setLogout,
        checkLogin,
        setCheckLogin,
        CreatePasswordApi,
        publish,
        setPublish,
        passModal,
        setIsPassModal,
        addSponsorApi,
        AddSponsorLevelApi,
        sponsorsLevel,
        setSponsorsLevel,
        getSponsorLevelApi,
        updateSponsorLevelApi,
        deleteSponsorlevelApi,
        searchSponsorLevelApi,
        getSponsorList,
        setSponsorsList,
        sponsorsList,
        updateSponsorApi,
        deleteSponsorApi,
        searchSponsorApi,
        ButtonLoader,
        setButtonLoader,
        speakers,
        setSpeakers,
        updateSpeakerApi,
        deleteSpeakerApi,
        searchSpeakerApi,
        addAttendeesApi,
        user,
        setUser,
        getUserApi,
        updateUserApi,
        deleteUserApi,
        searchUserApi,
        AddFaqApi,
        faqList,
        setFaqList,
        getFaqApi,
        globalLoader,
        getSpeakersApi,
        createAgendaApi,
        getAgendaById,
        agendaList,
        setAgendaList,
        updateAgendaApi,
        changePasswordApi,
        addPollApi,
        getPollApi,
        pollList,
        updateFaqApi,
        deleteBtn,
        setDeleteBtn,
        deleteFaqApi,
        deleteBtnId,
        setDeleteBtnId,
        deletePollApi,
        deleteAgendaApi,
        getCountryApi,
        countryList,
        setCountryList,
        searchAgendaApi,
        importUserApi,
        getPublishApi,
        addPublishApi,
        updatePollApi,
        publishList,
        AllCountApi,
        allCount,
        addAnnouncementApi,
        getAnnouncementApi,
        announcementList,
        updateAnnouncementApi,
        deleteAnnouncementApi,
        addExhibitorApi,
        getExhibitorApi,
        exhibitorList,
        deleteExhibitorApi,
        disableSave,
        setDisableSave,
        updateExhibitorApi,
        getLeaderboardApi,
        leaderboardList,
        getAllPostsApi,
        allPostsList,
        getAudienceById,
        adminInfo,
        eventPrivacyPolicyApi,
        getNotificationApi,
        notificationList,
        eventTermsAndConditionApi,
        enablePollApi,
        pollStatus,
        disablePollApi,
        currentPage,
        setCurrentPage,
        publishPaginationApi,
        pageCount,
        setPageCount,
        totalCount,
        setTotalCount,
        agendaPaginationApi,
        faqPaginationApi,
        announcementPaginationApi,
        exhibitorPaginationApi,
        leaderboardPaginationApi,
        pollPaginationApi,
        getMeetingsListApi,
        meetingsList,
        speakerPaginationApi,
        attendeesPaginationApi,
        sponsorPaginationApi,
        forgotPasswordApi,
        resetPasswordApi,

        getAgendaDatesApi,
        agendaDatesState,
        getAgendaSessionState,
        getSessionApi,
        startDate,

        updateAudienceApi,
        setAdminInfo,
        pollModalBtn,
        setPollModalBtn,
        disablePostApi,
        postDisabled,
       
        searchExhibitorApi,
        createRegistrationApi,
        getRegistraition,
        getRegData,
        speakerModal,
        setSpeakerModal,
        checkResponse,
        getEmailTemplate,
        emailTemplate,
        updateEmailTemplate,
        postMeetingEmailApi,
        searchAnnouncementApi,
        meetingNotificationApi,
        searchCheckInApi,
        checkInList,
        setCheckInList,
        getDummyGraphDataApi,
        graphData,
        createContactListApi,
        isContactList,
        setIsContactList,
        isSendMail,
        setIsSendMail,
        getContactListApi,
        contactList,
        createContactApi,
        getCreateContactListApi,
        createContactList,
        deleteContactListApi,
        updateContactListApi ,
        updateContactApi,
        deleteContactApi,
        setContactListId,
        setCreateContactList,
        setContactList,
        contactListPaginationApi,
        createContactListPaginationApi,
        searchContactListApi,
        searchCreateContactListApi,
        sendEmailApi,
        findOneExhibitorApi,
        editExhibitor,
        setEditExhibitor,
        getActivityReportApi,
        activityReport,
        findOneSpeakerApi,
        findOneAttendeeApi,
        findOneAgendaApi,
        findOneSponsorApi,
        getExhibitorReportApi,
        exhibitorReport,
        graphData,
        getSingleExhibitorReportApi,
        singleExhibitorReport,
        getAttendeeReportApi,
        attendeeReport,
        status, setStatus,
        getSponsorReportApi,
        sponsorReport,
        getSingleSponsorReportApi,
        singleSponsorReport,
        option,setOption,
        getAgendaReportApi,
        getSingleAttendeeReportApi,
        singleAttendeeReport,
        getAgendaReportApi,
        agendaReport,
        getSingleAgendaReportApi,
        singleAgendaReport,
        getSpeakerReportApi,speakerReport,
        getSingleSpeakertApi,singleSpeakerReport,
        sendAttendeeEmailApi,
        getSponsorDateAndTime,
        sponsorTime,getExhibitorDateAndTime,
        setGetRegData,
        deleteRegistrationColumnApi,
        getDisablePostApi,disablePosts,
        changeAgendaStatusApi,isSpinner,setIsSpinner

       
       

      

      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;

// Updated build date 10/07/2023
